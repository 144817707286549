<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Certification from "../components/CertificationsItem.vue";
import Modal from "../../views/spa/_elements/Modal.vue";
import eacs from "../../views/spa/mixins/eacs";
import axios from "axios";
import { mapState } from "pinia";
import { useClientsStore } from "../stores/clients";

export default {
    name: "CertificationsListado",
    components: {
        Certification,
        Modal,
    },
    mixins: [eacs],
    data() {
        return {
            // OK
            norms: {},
            accreditations: {},
            phases: {},
            eacs: {},

            newCertificationForm: {
                client_id: null,
                norm_id: null,
                accreditation_id: null,
            },

            // certificationAlert: {
            //     action: '',
            //     status: '',
            //     message: '',
            // },

            months: this.$store.state.miscelaneous.months,

            openFiltersMenu: false,
            certificationFilters: {
                expiredVeto: false,
                expiredDocs: false,
                expiredCompleted: false,
                serviceWithoutSchedule: false,
                certificationWithoutServices: false,
                nextService: "",
                nextServiceDate: {
                    month: "",
                    year: "",
                },
                certifications: {
                    norm: "",
                    accreditation: "",
                    eac: "",
                },
            },

            // eliminar
            events: null,

            // para revisar
            searchCertification: "",
            services: null,
            eacs: null,
            userData: {},
            alert: {
                message: "",
                type: "",
            },
        };
    },
    computed: {
        ...mapState(useClientsStore, ["clients"]),
        // OK
        ...mapGetters({
            certificationAlert: "certifications/certificationAlert",
            getCertifications: "certifications/getCertifications",
            getToken: "user/getToken",
        }),
        filteredCertifications() {
            let certifications = this.getCertifications;

            let month =
                this.certificationFilters.nextServiceDate.month.padStart(
                    2,
                    "0"
                );
            let year = this.certificationFilters.nextServiceDate.year;

            if (
                this.certificationFilters.nextServiceDate.month != "" &&
                this.certificationFilters.nextServiceDate.year != ""
            ) {
                certifications = certifications.filter((cert) => {
                    return cert.certification_phases.some(
                        (service) =>
                            service.expires != null &&
                            service.expires != undefined &&
                            service.expires.split("-")[0] == year &&
                            service.expires.split("-")[1] == month
                    );
                });
            }

            if (this.certificationFilters.nextService) {
                certifications = certifications.filter(this.nextServiceFilter);
            }

            if (this.certificationFilters.serviceWithoutSchedule) {
                certifications = certifications.filter(
                    this.checkIsNullScheduledAt
                );
            }

            if (this.certificationFilters.certifications.norm) {
                certifications = certifications.filter(
                    (cert) =>
                        cert.norm_id ==
                        this.certificationFilters.certifications.norm
                );
            }

            if (this.certificationFilters.certifications.accreditation) {
                certifications = certifications.filter(
                    (cert) =>
                        cert.accreditation_id ==
                        this.certificationFilters.certifications.accreditation
                );
            }

            if (this.certificationFilters.certifications.eac) {
                certifications = certifications.filter((cert) => {
                    return cert.eacs_norms.some(
                        (eac) =>
                            eac.id ==
                            this.certificationFilters.certifications.eac
                    );
                });
            }

            if (this.certificationFilters.certificationWithoutServices) {
                certifications = certifications.filter((cert) => {
                    !cert.certification_phases.length;
                });
            }

            if (this.searchCertification) {
                certifications = certifications.filter((cert) => {
                    return cert.client.account_name
                        .toLowerCase()
                        .includes(this.searchCertification.toLowerCase());
                });
            }

            return certifications.sort((a, b) =>
                a.client.account_name.localeCompare(b.client.account_name)
            );
        },
        normEacs() {
            if (this.certificationFilters.certifications.norm) {
                let results = this.norms.filter(
                    (norm) =>
                        norm.id ===
                        this.certificationFilters.certifications.norm
                )[0].eacs_norms;

                results = this.sortNormEacsByName(results);

                // results.sort(function(a,b){
                //     if(!a.eac){
                //         return -1;
                //     }

                //     if(!b.eac){
                //         return 1;
                //     }

                //     return a.eac.eac.localeCompare(b.eac.eac, undefined, {numeric: true, sensitivity: 'base'})
                // });

                console.log("eacs: ", results);

                let filteredEacs = results.filter(
                    (eac) => eac.eac && eac.eac.id != null
                );

                // results.sort((a,b) => a.eac.eac.localeCompare(b.eac.eac, undefined, {numeric: true, sensitivity: 'base'}));

                return filteredEacs;
            }
        },
        clientsQty() {
            let certs = this.filteredCertifications;
            var clientsQty = [];

            for (let i = 0; i < certs.length; i++) {
                if (!clientsQty.includes(certs[i].client.zoho_id)) {
                    clientsQty.push(certs[i].client.zoho_id);
                }
            }
            return clientsQty.length;
        },

        // A REVISAR
        type() {
            return "alert-" + this.alert.type;
        },
        years() {
            let yearsArray = [];
            for (let i = 2019; i <= 2060; i++) {
                yearsArray.push(i);
            }

            return yearsArray;
        },
    },
    methods: {
        ...mapActions({
            //    'storeCertifications': 'certifications/storeCertifications',
            storeCertification: "certifications/storeCertification",
        }),
        ...mapMutations({
            changeCertificationAlert: "certifications/changeCertificationAlert",
            clearCertificationAlert: "certifications/clearCertificationAlert",
        }),
        nextServiceFilter(certification) {
            let checkServiceOpened = false;

            if (certification.first_opened_certification_phase) {
                checkServiceOpened =
                    certification.first_opened_certification_phase.phase_id ==
                    this.certificationFilters.nextService
                        ? true
                        : false;
            }

            return checkServiceOpened;
        },

        checkIsNullScheduledAt(service) {
            let checkScheduledAt = false;

            if (service.first_opened_certification_phase) {
                checkScheduledAt = service.first_opened_certification_phase
                    .scheduled_at
                    ? true
                    : false;
            }

            return checkScheduledAt;
        },
        // OK
        getNorms() {
            return this.$axios.get("norms");
        },
        getAccreditations() {
            return this.$axios.get("accreditations");
        },
        getPhases() {
            return this.$axios.get("phases");
        },
        getClients() {
            return this.$axios.get("clients");
        },
        getEacs() {
            return this.$axios.get("eacss");
        },
        getEacs() {
            return this.$axios.get("eacs");
        },
        // getInvoices(){ return this.$axios.get('invoices'); },
        clearNewCertificationForm() {
            this.newCertificationForm.client_id = null;
            this.newCertificationForm.norm_id = null;
            this.newCertificationForm.accreditation_id = null;
        },
        clearCertificationFilters() {
            this.certificationFilters.nextServiceDate.month = "";
            this.certificationFilters.nextServiceDate.year = "";
            this.certificationFilters.certifications.norm = "";
            this.certificationFilters.certifications.accreditation = "";
            this.certificationFilters.certifications.eac = "";
            this.certificationFilters.nextService = "";
            this.certificationFilters.certificationWithoutServices = false;
            this.certificationFilters.serviceWithoutSchedule = false;
            this.searchCertification = "";
            this.openFiltersMenu = false;
        },
        storeCertificationFn() {
            this.storeCertification(this.newCertificationForm).then(() => {
                this.$refs.newCertificationModal.closeModal();
                this.clearNewCertificationForm();
            });
        },
    },
    async created() {
        await axios
            .all([
                this.getNorms(),
                this.getAccreditations(),
                this.getClients(),
                this.getPhases(),
                this.getEacs(),
            ])
            .then(
                axios.spread((...results) => {
                    this.norms = results[0].data.data;
                    this.accreditations = results[1].data.data;
                    this.clients = results[2].data.data;
                    this.phases = results[3].data.data;
                    this.eacs = results[4].data.data;
                    this.certifications = this.$store.state.certifications;
                })
            )
            .catch((errors) => {});
    },
};
</script>

<template>
    <div class="mx-8">
        <div>
            <header class="flex flex-row justify-between w-full">
                <div
                    class="flex flex-col justify-start items-baseline my-2 w-1/5"
                >
                    <h1 class="text-xl text-tuvdarkblue">Certificaciones</h1>
                    <p class="text-tuvdarkblue ml-6 md:ml-0">
                        Administración de certificaciones
                    </p>
                </div>
                <div class="my-2 relative w-3/5">
                    <input
                        class="bg-white placeholder:text-slate-400 border border-tuvgray placeholder:italic text-xs pl-10 self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none"
                        type="text"
                        v-model="searchCertification"
                        id="searchCertification"
                        placeholder="Buscar por nombre de cliente..."
                        @keyup.esc="searchCertification = ''"
                        tabindex="0"
                    />
                    <font-awesome-icon
                        icon="fa-duotone fa-magnifying-glass"
                        class="fa-duotone fa-magnifying-glass text-lg absolute left-3 top-4 text-slate-800"
                    />
                </div>
                <div class="my-2 w-1/5 flex justify-end">
                    <button
                        v-title
                        class="bg-tuvblue cursor-pointer px-4 py-1 uppercase tracking-wide text-sm text-white ml-2 drop-shadow text-right"
                        @click="$refs.newCertificationModal.openModal()"
                        title="Crear certificación"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'fa-file-circle-plus']"
                            class="text-xl"
                            style="--fa-secondary-opacity: 0.6"
                        />
                    </button>
                    <span style="font-size: 0px">{{ openFiltersMenu }}</span>
                    <button
                        v-title
                        class="bg-tuvblue cursor-pointer px-4 py-1 uppercase tracking-wide text-sm text-white ml-2 drop-shadow text-right"
                        title="Filtrar certificaciones"
                        @click="openFiltersMenu = true"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'filter-list']"
                            class="text-xl"
                            style="--fa-secondary-opacity: 0.6"
                        />
                    </button>

                    <button
                        v-title
                        class="bg-tuvblue cursor-pointer px-4 py-1 uppercase tracking-wide text-sm text-white ml-2 drop-shadow text-right"
                        title="Información"
                        @click="$refs.certificationsInfo.openModal()"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'circle-info']"
                            class="text-xl"
                            style="--fa-secondary-opacity: 0.6"
                        />
                    </button>
                </div>
            </header>
        </div>

        <p class="my-4">
            Total clientes: {{ clientsQty }} | Total certificados:
            {{ filteredCertifications.length }}
        </p>

        <div
            class="bg-slate-300 p-4 rounded-xl text-neutral-800 transition-all ease-in-out"
            v-if="openFiltersMenu"
        >
            <div class="flex flex-row items-center justify-start">
                <div class="flex flex-col justify-start mr-4">
                    <span class="mr-4 font-bold">Fecha de vencimiento: </span>
                    <div class="flex flex-row justify-center items-center">
                        <select
                            id="usersFilterMonth"
                            v-model="certificationFilters.nextServiceDate.month"
                            class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-40 mb-0 outline-none focus:outline-none my-2 mr-2"
                        >
                            <option selected value="">Mes</option>
                            <option
                                v-for="(month, key) in months"
                                :key="key"
                                :value="key"
                            >
                                {{ month }}
                            </option>
                        </select>
                        <select
                            id="usersFilterYear"
                            v-model="certificationFilters.nextServiceDate.year"
                            class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-40 mb-0 outline-none focus:outline-none my-2 mr-2"
                        >
                            <option selected value="">Año</option>
                            <option
                                v-for="year in years"
                                :key="year"
                                :value="year"
                            >
                                {{ year }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="flex flex-col justify-start mr-4">
                    <span class="mr-4 font-bold">Certificaciones: </span>
                    <div class="flex flex-row justify-center items-center">
                        <select
                            id="usersFilterNorm"
                            v-model="certificationFilters.certifications.norm"
                            class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-40 mb-0 outline-none focus:outline-none my-2 mr-2"
                        >
                            <option selected value="">Norma</option>
                            <option
                                v-for="(norm, index) in norms"
                                :key="index"
                                :value="norm.id"
                            >
                                {{ norm.norm }}
                            </option>
                        </select>
                        <select
                            id="usersFilterAccr"
                            v-model="
                                certificationFilters.certifications
                                    .accreditation
                            "
                            class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-40 mb-0 outline-none focus:outline-none my-2 mr-2"
                        >
                            <option selected value="">Acreditación</option>
                            <option
                                v-for="(accreditation, index) in accreditations"
                                :key="index"
                                :value="accreditation.id"
                            >
                                {{ accreditation.accreditation }}
                            </option>
                        </select>
                        <select
                            id="usersFilterEac"
                            v-model="certificationFilters.certifications.eac"
                            class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none my-2"
                        >
                            <option selected value="">Eac</option>
                            <option
                                v-for="eac in normEacs"
                                :key="eac.id"
                                :value="eac.id"
                            >
                                {{ eac.eac.eac }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="flex flex-col justify-start mr-4">
                    <span class="mr-4 font-bold">Servicios: </span>
                    <div class="flex flex-row justify-center items-center">
                        <select
                            id="usersFilterNorm"
                            v-model="certificationFilters.nextService"
                            class="bg-slate-100 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl self-center focus:text-slate-700 w-40 mb-0 outline-none focus:outline-none my-2 mr-2"
                        >
                            <option selected value="">Próxima etapa</option>
                            <option
                                v-for="(phase, index) in phases"
                                :key="index"
                                :value="phase.id"
                            >
                                {{ phase.phase_name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="flex flex-col justify-start mr-4">
                    <span class="mr-4 font-bold">Otros filtros: </span>
                    <div class="flex flex-row justify-center items-center h-6">
                        <input
                            class="appearance-none checked:bg-blue-600 border text-xs mb-0 rounded-xl h-4 border-neutral-400 bg-neutral-100 w-4 mr-2"
                            type="checkbox"
                            id="serviceWithoutSchedule"
                            v-model="
                                certificationFilters.serviceWithoutSchedule
                            "
                        />
                        <label for="serviceWithoutSchedule">
                            Sin agenda cargada</label
                        >

                        <!-- <input class="appearance-none checked:bg-blue-600 border text-xs mb-0 rounded-xl h-4 border-neutral-400 bg-neutral-100 w-4 mx-2" type="checkbox" id="serviceWithoutSchedule" v-model="certificationFilters.certificationWithoutServices">
                    <label for="certificationWithoutServices"> Sin servicios</label> -->
                    </div>
                </div>

                <div class="flex flex-row justify-center items-center ml-4">
                    <span
                        class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer disabled:cursor-not-allowed px-6 py-3 uppercase rounded-xl tracking-wide text-sm active:shadow-none disabled:shadow transition-shadow ease-out duration-100 mr-2 text-slate-600 disabled:text-slate-400"
                        @click="clearCertificationFilters()"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-filter-slash"
                            class="text-xl text-slate-600"
                        />
                    </span>
                </div>
            </div>
        </div>
        <!-- <div class="bg-neutral-200 p-4 rounded-xl text-neutral-800 transition-all ease-in-out">
        <div class="flex flex-row items-center justify-between">
            <div class="flex flex-row justify-center items-center">
                <input class="border mb-0 rounded checked:bg-slate-600 border-neutral-400 bg-neutral-100" type="checkbox" id="without_services" v-model="certificationFilters.withoutService">
                <label class="ml-2" for="without_services">Sin Servicios</label>
            </div>

        </div>
    </div> -->

        <div
            class="bg-emerald-500 p-4 text-white rounded-xl flex flex-row items-center justify-between"
            v-if="certificationAlert.action && certificationAlert.status < 300"
        >
            <span>{{ certificationAlert.message }}</span>
            <font-awesome-icon
                icon="fa-duotone fa-circle-xmark"
                size="2x"
                class="cursor-pointer"
                @click="clearCertificationAlert()"
            />
        </div>

        <table class="table w-full border-collapse border-none rounded text-sm">
            <thead class="rounded-t table-row-group">
                <tr class="table-row">
                    <th
                        class="table-cell font-bold w-2/12 border-none text-neutral-600 text-left p-2"
                    >
                        Cliente
                    </th>
                    <th
                        class="table-cell font-bold w-1/12 border-none text-neutral-600 text-left p-2"
                    >
                        Norma
                    </th>
                    <th
                        class="table-cell font-bold w-1/12 border-none text-neutral-600 text-left p-2"
                    >
                        Acreditación
                    </th>
                    <th
                        class="table-cell font-bold w-1/12 border-none text-neutral-600 text-left p-2"
                    >
                        EACs
                    </th>
                    <th
                        class="table-cell font-bold w-2/12 border-none text-neutral-600 text-left p-2"
                    >
                        Próxima etapa
                    </th>
                    <th
                        class="table-cell font-bold v-1/12 border-none text-neutral-600 text-left p-2"
                    >
                        MD
                    </th>
                    <th
                        class="table-cell font-bold w-1/12 border-none text-neutral-600 text-left p-2"
                    >
                        Emite Cert
                    </th>
                    <th
                        class="table-cell font-bold w-1/12 border-none text-neutral-600 text-left p-2"
                    >
                        Tiene Cert
                    </th>
                    <th
                        class="table-cell font-bold w-1/12 border-none text-neutral-600 text-left p-2"
                    >
                        Agenda
                    </th>
                    <th
                        class="table-cell font-bold w-1/12 border-none text-neutral-600 text-left p-2"
                    >
                        Acciones
                    </th>
                </tr>
            </thead>

            <tbody
                class="table-row-group"
                v-if="filteredCertifications.length > 0"
            >
                <certification
                    v-for="(certification, index) in filteredCertifications"
                    :key="index"
                    :certification="certification"
                    :norms="norms"
                    :phases="phases"
                    :accreditations="accreditations"
                    :events="events"
                    @open-modal="openModalFn()"
                >
                </certification>
            </tbody>

            <div v-else-if="filteredCertifications.length == 0">
                No se encontraron certificaciones...
            </div>
            <div v-else>Cargando certificaciones...</div>
        </table>

        <modal ref="newCertificationModal">
            <template v-slot:header>
                <h2 class="text-xl">Nueva certificación</h2>
            </template>

            <template v-slot:body>
                <div
                    class="bg-rose-500 px-4 py-2 mb-4 text-white rounded-xl flex flex-row items-center justify-between"
                    v-if="
                        certificationAlert.action == 'store' &&
                        certificationAlert.status >= 300
                    "
                >
                    <ul>
                        <li
                            v-for="error in certificationAlert.errors"
                            :key="error[0]"
                        >
                            {{ error[0] }}
                        </li>
                    </ul>
                    <font-awesome-icon
                        icon="fa-duotone fa-circle-xmark"
                        size="2x"
                        class="cursor-pointer"
                        @click="clearCertificationAlert()"
                    />
                </div>
                <div class="grid grid-cols-3 gap-4">
                    <div>
                        <p>Cliente</p>
                        <select
                            class="rounded-xl"
                            v-model="newCertificationForm.client_id"
                            name="client"
                            id="client"
                            @chabge="clearCertificationAlert()"
                        >
                            <option value="" selected disabled>
                                -- Seleccione cliente --
                            </option>
                            <option
                                v-for="client in clients"
                                :key="client.zoho_id"
                                :value="client.zoho_id"
                            >
                                {{ client.account_name }}
                            </option>
                        </select>
                    </div>

                    <div>
                        <p>Norma</p>
                        <select
                            class="rounded-xl"
                            v-model="newCertificationForm.norm_id"
                            name="norm"
                            id="norm"
                            @chabge="clearCertificationAlert()"
                        >
                            <option
                                v-for="norm in norms"
                                :key="norm.id"
                                :value="norm.id"
                            >
                                {{ norm.norm }}
                            </option>
                        </select>
                    </div>

                    <div>
                        <p>Acreditación</p>
                        <select
                            class="rounded-xl"
                            v-model="newCertificationForm.accreditation_id"
                            name="accreditation"
                            id="accreditation"
                            @chabge="clearCertificationAlert()"
                        >
                            <option
                                v-for="accreditation in accreditations"
                                :key="accreditation.id"
                                :value="accreditation.id"
                            >
                                {{ accreditation.accreditation }}
                            </option>
                        </select>
                    </div>
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <button
                        class="bg-neutral-400 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out mr-2"
                        @click="
                            $refs.newCertificationModal.closeModal();
                            clearCertificationAlert();
                            clearNewCertificationForm();
                        "
                    >
                        Cancelar
                    </button>
                    <button
                        class="bg-blue-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out"
                        @click="
                            storeCertificationFn();
                            clearCertificationAlert();
                        "
                    >
                        Crear certificación
                    </button>
                </div>
            </template>
        </modal>

        <modal ref="certificationsInfo">
            <template v-slot:header>
                <h2 class="text-xl">Información sobre las certificaciones</h2>
            </template>

            <template v-slot:body>
                <h3 class="text-base font-medium">Mes 12</h3>
                <p>
                    Mes 12 se tiene que hacer antes de los 12 meses de la fase
                    2. La suspensión arranca a los 366 días con una validez de
                    150 días para recovarla.
                </p>

                <h3 class="text-base font-medium mt-4">Mes 24</h3>
                <p>Mes 24, se tiene que hacer en el año calendario.</p>

                <h3 class="text-base font-medium mt-4">Recertificación</h3>
                <p>
                    Recertificación: Desde el vencimiento del certificado, se
                    puede revocar en hasta 6 meses.<br />
                    Durante el tiempo desde el vencimiento hasta que se emita el
                    nuevo certificado, el cliente queda descubierto de la
                    certificación.
                </p>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <button
                        class="bg-neutral-400 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out mr-2"
                        @click="$refs.certificationsInfo.closeModal()"
                    >
                        Cerrar
                    </button>
                    <!-- <button class=" bg-blue-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out" @click="storeCertificationFn(); clearCertificationAlert();">Crear certificación</button> -->
                </div>
            </template>
        </modal>
    </div>
</template>
<style lang="scss" scoped>
select,
input[type="date"] {
    height: 36px;
    width: 100%;
    margin-bottom: 0;
}
</style>
