<script>
import { mapGetters, mapActions } from "vuex";
import Modal from "../../views/spa/_elements/Modal.vue";
import CertificationEacModal from "../../views/spa/pages/operador/certifications/CertificationEacModal.vue";
import _debounce from "lodash/debounce";
import moment from "moment";
import axios from "axios";

export default {
    name: "Certification",
    components: {
        Modal,
        CertificationEacModal,
    },
    props: ["certification", "norms", "accreditations", "phases"],
    data() {
        return {
            moment: moment,
            phaseForm: {
                expires: null,
                phase_id: null,
            },
            updateClient: false,
            updateNorm: false,
            updateAccreditation: false,
            updateMD: false,
            updateScheduledAt: false,
            updateComments: false,

            updatePhaseForm: {
                id: null,
                certification_id: null,
                phase_id: null,
                expires: null,
                man_days: 0,
                has_cert: 0,
                emit_cert: 0,
                scheduled_at: null,
                docs_expiration: 0,
                docs: 0,
                veto: 0,
                completed: 0,
                comments: "",
            },
            expirationAlerts: {
                docs: 2,
                veto: 20,
                completed: 35,
            },

            certificationAlert: false,
            certificationAlertText: "",

            phaseFormField: {},

            startNewService: false,

            isModalVisible: false,
            formEditCertification: false,
            docs_expiration_value: this.certification
                .first_opened_certification_phase?.docs_expiration
                ? this.certification.first_opened_certification_phase
                      .docs_expiration
                : 0,

            emptyCertificationData: {
                id: null,
                certification_id: this.certification.id,
            },
        };
    },
    methods: {
        ...mapActions({
            updateCertification: "certifications/updateCertification",
            deleteCertification: "certifications/deleteCertification",
            //    'updateCertification': 'certifications/updateCertification',
            updateCertificationService:
                "certifications/updateCertificationService",
            //    'updateCertificationData': 'certifications/updateCertificationData',
            //    'storeCertificationData': 'certifications/storeCertificationData',
        }),
        updateCertificationFn() {
            // FALTA CONFIGURAR FRONTEND
            this.updateCertification(this.certification);
        },
        async deleteCertificationFn() {
            await this.deleteCertification(this.certification).then(() => {
                this.$refs.eliminarCertificacion.closeModal();
            });
        },
        scheduledAt(certification) {
            return moment(
                certification.first_opened_certification_phase.scheduled_at
            ).format("DD/MM/YYYY");
        },
        phaseExpiration(phase) {
            return moment(phase.expires).format("L");
        },
        openFieldUpdateServiceForm(field, key, value) {
            if (!(key in this.phaseFormField)) {
                // this.$set(this.phaseFormField, key, true);
                this.phaseFormField[key] = true;
            } else {
                this.phaseFormField[key] = true;
            }
            this.updatePhaseForm[field] = value;
        },

        clearUpdateServiceForm() {
            this.updatePhaseForm.id = null;
            this.updatePhaseForm.certification_id = null;
            this.updatePhaseForm.phase_id = null;
            this.updatePhaseForm.expires = null;
            this.updatePhaseForm.man_days = 0;
            this.updatePhaseForm.has_cert = 0;
            this.updatePhaseForm.emit_cert = 0;
            this.updatePhaseForm.scheduled_at = null;
            this.updatePhaseForm.docs_expiration = 0;
            this.updatePhaseForm.docs = 0;
            this.updatePhaseForm.veto = 0;
            this.updatePhaseForm.completed = 0;
            this.updatePhaseForm.comments = "";
        },
        updateCertificationServiceFn(data) {
            const newServiceValues = {
                id: this.certification.first_opened_certification_phase.id,
                certification_id:
                    this.certification.first_opened_certification_phase
                        .certification_id,
                phase_id:
                    this.certification.first_opened_certification_phase
                        .phase_id,
                expires:
                    this.certification.first_opened_certification_phase.expires,
                man_days:
                    this.certification.first_opened_certification_phase
                        .man_days,
                has_cert:
                    this.certification.first_opened_certification_phase
                        .has_cert,
                emit_cert:
                    this.certification.first_opened_certification_phase
                        .emit_cert,
                scheduled_at:
                    this.certification.first_opened_certification_phase
                        .scheduled_at,
                docs_expiration:
                    this.certification.first_opened_certification_phase
                        .docs_expiration,
                docs: this.certification.first_opened_certification_phase.docs,
                veto: this.certification.first_opened_certification_phase.veto,
                completed:
                    this.certification.first_opened_certification_phase
                        .completed,
                comments:
                    this.certification.first_opened_certification_phase
                        .comments,
            };

            if ("man_days" == data) {
                newServiceValues.man_days =
                    this.certification.first_opened_certification_phase?.man_days.replace(
                        /,/g,
                        "."
                    );
                this.certification.first_opened_certification_phase.man_days =
                    this.certification.first_opened_certification_phase?.man_days.replace(
                        /,/g,
                        "."
                    );
                this.updateMD = false;
            }

            if ("has_cert" == data) {
                if (
                    this.certification.first_opened_certification_phase
                        .has_cert == 1
                ) {
                    this.certification.first_opened_certification_phase.has_cert = 0;
                    newServiceValues.has_cert = 0;
                } else {
                    this.certification.first_opened_certification_phase.has_cert = 1;
                    newServiceValues.has_cert = 1;
                }
            }

            if ("emit_cert" == data) {
                if (
                    this.certification.first_opened_certification_phase
                        .emit_cert == 1
                ) {
                    this.certification.first_opened_certification_phase.emit_cert = 0;
                    newServiceValues.emit_cert = 0;
                } else {
                    this.certification.first_opened_certification_phase.emit_cert = 1;
                    newServiceValues.emit_cert = 1;
                }
            }

            if ("docs_expiration" == data) {
                newServiceValues.docs_expiration = this.docs_expiration_value;
            }

            if ("scheduled_at" == data) {
                newServiceValues.scheduled_at =
                    this.certification.first_opened_certification_phase.scheduled_at;
                this.updateScheduledAt = false;
            }

            if ("docs" == data) {
                if (
                    this.certification.first_opened_certification_phase.docs ==
                    1
                ) {
                    this.certification.first_opened_certification_phase.docs = 0;
                    newServiceValues.docs = 0;
                } else {
                    this.certification.first_opened_certification_phase.docs = 1;
                    newServiceValues.docs = 1;
                }
            }

            if ("veto" == data) {
                if (
                    this.certification.first_opened_certification_phase.veto ==
                    1
                ) {
                    this.certification.first_opened_certification_phase.veto = 0;
                    newServiceValues.veto = 0;
                } else {
                    this.certification.first_opened_certification_phase.veto = 1;
                    newServiceValues.veto = 1;
                }
            }

            if ("completed" == data) {
                if (
                    this.certification.first_opened_certification_phase
                        .completed == 1
                ) {
                    this.certification.first_opened_certification_phase.completed = 0;
                    newServiceValues.completed = 0;
                } else {
                    this.certification.first_opened_certification_phase.completed = 1;
                    newServiceValues.completed = 1;
                }
            }

            if ("comments" == data) {
                newServiceValues.comments =
                    this.certification.first_opened_certification_phase.comments;
                this.updateComments = false;
            }

            this.updateCertificationService(newServiceValues);
        },
        updateCertificationPhaseByPhase(phase, field, key) {
            this.updatePhaseForm.id = phase.id;
            this.updatePhaseForm.certification_id = phase.certification_id;
            this.updatePhaseForm.phase_id = phase.phase_id;
            this.updatePhaseForm.has_cert = phase.has_cert;
            this.updatePhaseForm.emit_cert = phase.emit_cert;
            this.updatePhaseForm.scheduled_at = phase.scheduled_at;
            this.updatePhaseForm.docs_expiration = phase.docs_expiration;
            this.updatePhaseForm.docs = phase.docs;
            this.updatePhaseForm.veto = phase.veto;
            this.updatePhaseForm.completed = phase.completed;
            this.updatePhaseForm.comments = phase.comments;

            if ("man_days" == key) {
                console.log("man days");
                this.updatePhaseForm.man_days =
                    this.updatePhaseForm.man_days?.replace(/,/g, ".");
                this.phaseFormField[field] = false;
                // this.updateMD = false;
            } else {
                this.updatePhaseForm.man_days = phase.man_days;
            }
            if ("expires" == key) {
                this.phaseFormField[field] = false;
            } else {
                this.updatePhaseForm.expires = phase.expires;
            }

            this.updateCertificationService(this.updatePhaseForm);

            this.clearUpdateServiceForm();
        },

        deletePhase(id) {
            axios
                .delete(
                    "certifications/" + this.certification.id + "/phases/" + id,
                    {
                        headers: { Authorization: "Bearer " + this.getToken },
                    }
                )
                .then((response) => {
                    this.$store.commit(
                        "certifications/updateCertification",
                        response.data.data
                    );
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
        storeCertificationServices() {
            axios
                .post(
                    "certifications/" + this.certification.id + "/phases",
                    {
                        phase_id: this.phaseForm.phase_id,
                        expires: this.phaseForm.expires,
                    },
                    {
                        headers: { Authorization: "Bearer " + this.getToken },
                    }
                )
                .then((response) => {
                    this.$store.commit(
                        "certifications/updateCertification",
                        response.data.data
                    );
                    this.phaseForm.expires = null;
                    this.phaseForm.phase_id = null;
                })
                .catch((error) => {
                    console.log("store new phase: ", error.response);
                });
        },

        goToCertificationDetails(certification_id) {
            this.$router.push({
                name: "CertificationDetails",
                params: { certification_id: certification_id },
            });
        },
        bgBadgeAuditorStatus(id) {
            let bgColor = null;
            switch (id) {
                case 1:
                    bgColor = "bg-fuchsia-500 text-white";
                    break;
                case 2:
                    bgColor = "bg-purple-500 text-white";
                    break;
                case 3:
                    bgColor = "bg-indigo-500 text-white";
                    break;
                case 4:
                    bgColor = "bg-sky-500 text-white";
                    break;
                default:
                    bgColor = "bg-blue-500 text-white";
                    break;
            }
            return bgColor;
        },
        // A REVISAR
    },
    computed: {
        ...mapGetters({
            getCertification: "certifications/certification",
            getToken: "user/getToken",
        }),
        nextPhase() {
            if (
                this.certification.first_opened_certification_phase.phase_id ==
                4
            ) {
                var nextPhaseId = 2;
            } else {
                var nextPhaseId =
                    this.certification.first_opened_certification_phase
                        .phase_id + 1;
            }

            var nextPhaseService = this.phases.find(
                (phase) => phase.id == nextPhaseId
            );

            return nextPhaseService.phase;
        },
        bgColor() {
            const today = moment();
            let scheduled_at = null;
            let emit_cert = null;
            let has_cert = null;
            let alerts = [];
            this.certificationAlert = false;

            const redBackground =
                "border-l-4 border-l-rose-600 hover:bg-slate-50";

            if (this.certification.first_opened_certification_phase) {
                scheduled_at = moment(
                    this.certification.first_opened_certification_phase
                        .scheduled_at
                );
                emit_cert =
                    this.certification.first_opened_certification_phase
                        .emit_cert;
                has_cert =
                    this.certification.first_opened_certification_phase
                        .has_cert;
            } else {
                return;
            }

            if (
                this.certification.first_opened_certification_phase.emit_cert &&
                !this.certification.first_opened_certification_phase
                    .scheduled_at
            ) {
                alerts.push("Etapa sin programar");
                this.certificationAlert = true;
            }

            if (
                this.certification.first_opened_certification_phase.docs == 0 &&
                today.diff(scheduled_at, "days") > this.expirationAlerts.docs
            ) {
                alerts.push("Faltan los documentos");
                this.certificationAlert = true;
                // return redBackground;
            }
            if (
                this.certification.first_opened_certification_phase.veto == 0 &&
                today.diff(scheduled_at, "days") > this.expirationAlerts.veto
            ) {
                alerts.push("Falta reazliar el veto");
                this.certificationAlert = true;
                // return redBackground;
            }
            if (
                this.certification.first_opened_certification_phase.completed ==
                    0 &&
                today.diff(scheduled_at, "days") >
                    this.expirationAlerts.completed
            ) {
                alerts.push("No está finalizado el servicio");
                this.certificationAlert = true;
                // return redBackground;
            }
            if (emit_cert == 1 && has_cert == 0) {
                alerts.push("Emite certificado pero no lo tiene");
                this.certificationAlert = true;
            }
            if (this.certificationAlert) {
                this.certificationAlertText = alerts.join(" | ");
                return redBackground;
            }

            this.certificationAlert = false;
            return "hover:bg-slate-50 border-l-4 border-l-transparent";
        },
        bgBadgeService() {
            let bgColor = null;
            switch (
                this.certification.first_opened_certification_phase.phase_id
            ) {
                case 1:
                    bgColor = "bg-fuchsia-500 text-white";
                    break;
                case 2:
                    bgColor = "bg-purple-500 text-white";
                    break;
                case 3:
                    bgColor = "bg-indigo-500 text-white";
                    break;
                case 4:
                    bgColor = "bg-sky-500 text-white";
                    break;
                default:
                    bgColor = "bg-transparent";
                    break;
            }
            return bgColor;
        },
        auditorsQty() {
            var count = 0;
            if (this.certification.eacs_norms) {
                this.certification.eacs_norms.forEach(function (eac_norm) {
                    if (eac_norm.auditors) {
                        count = count + eac_norm.auditors.length;
                    }
                });
            }

            return count;
        },
    },
};
</script>

<template>
    <tr
        class="hover:shadow-lg hover:shadow-neutral-200/50 px-4 py-2 text-slate-800"
        :class="[bgColor]"
        tabindex="0"
        v-if="certification.norm"
    >
        <!-- v-if="( (filters.nextPhaseDate.month != '' && filters.nextPhaseDate.year != '') && 
                (certification.first_opened_certification_phase.expires) &&
                (filters.nextPhaseDate.month == certification.first_opened_certification_phase.expires.split('-')[1] && filters.nextPhaseDate.year == certification.first_opened_certification_phase.split('-')[0]))"> -->

        <td
            class="table-cell border-none p-2 uppercase align-middle text-slate-600"
            v-if="certification.client.account_name"
        >
            {{ certification.client.account_name }}
            <span
                v-if="certificationAlert"
                v-title
                :title="certificationAlertText"
            >
                <font-awesome-icon
                    icon="fa-duotone fa-circle-exclamation"
                    style="--fa-primary-opacity: 0; --fa-secondary-opacity: 1"
                    class="text-rose-600 text-xl"
                /><br />
                <!-- <li class="text-xs italic text-rose-600 lowercase" v-for="alert in certificationAlertText" :key="alert">{{ alert }}</li> -->
            </span>
        </td>

        <td
            class="table-cell border-none text-sm p-2 align-middle text-slate-500"
            v-if="certification.norm"
        >
            {{ certification.norm.norm }}
        </td>

        <td
            class="table-cell border-none text-sm p-2 align-middle text-slate-500"
            v-if="certification.accreditation"
        >
            {{ certification.accreditation.accreditation }}
        </td>

        <td
            class="table-cell border-none text-sm p-2 cursor-pointer align-middle text-slate-500"
            @click="$refs.certEacsModal.openEditEacsModal()"
            v-title
            title="EACs"
        >
            <span
                v-if="
                    certification.eacs_norms &&
                    certification.eacs_norms.length > 0
                "
            >
                <span
                    v-for="(eacNorm, index) in certification.eacs_norms"
                    :key="eacNorm.id"
                >
                    <span v-if="index != 0">, </span>
                    <span v-if="eacNorm.eac">{{ eacNorm.eac.eac }}</span
                    ><span v-if="eacNorm.risk">{{
                        eacNorm.risk.risk
                    }}</span></span
                >
            </span>
            <span class="text-xs italic text-slate-400" v-else
                >Agregar EACs</span
            >
            <font-awesome-icon icon="fa-duotone fa-pen" />
        </td>

        <td
            class="table-cell border-none text-sm p-2 align-middle text-slate-500"
        >
            <span
                class="rounded-xl p-2 text-xs block text-center"
                :class="[bgBadgeService]"
                v-if="certification.first_opened_certification_phase"
                >{{
                    certification.first_opened_certification_phase.phase
                        .phase_name
                }}</span
            >
        </td>

        <td
            class="table-cell border-none text-sm p-2 align-middle text-slate-500"
            @click="updateMD = true"
        >
            <div
                v-if="certification.first_opened_certification_phase"
                v-title
                title="Man Days"
            >
                <div class="flex flex-col" v-if="updateMD">
                    <input
                        class="w-14 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                        type="text"
                        v-model="
                            certification.first_opened_certification_phase
                                .man_days
                        "
                        @keyup.enter="updateCertificationServiceFn('man_days')"
                        @keyup.esc="updateMD = false"
                        @click="select"
                    />
                </div>
                <span
                    class="cursor-pointer"
                    v-else-if="
                        certification.first_opened_certification_phase
                            .man_days != 0
                    "
                    >{{
                        certification.first_opened_certification_phase.man_days
                    }}</span
                >
                <span
                    class="cursor-pointer text-slate-400 italic text-xs"
                    v-else
                    >Agregar MD</span
                >
                <font-awesome-icon icon="fa-duotone fa-pen" />
            </div>
        </td>

        <td class="table-cell border-none text-sm p-2 text-center align-middle">
            <div
                @click="updateCertificationServiceFn('emit_cert')"
                class="cursor-pointer"
                v-if="certification.first_opened_certification_phase"
                v-title
                title="Emite certificado"
            >
                <span
                    v-if="
                        certification.first_opened_certification_phase
                            .emit_cert == 1
                    "
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-square-check"
                        style="--fa-secondary-opacity: 0"
                        swap-opacity
                        size="2x"
                        class="text-emerald-500"
                    />
                </span>
                <span v-else>
                    <font-awesome-icon
                        icon="fa-duotone fa-square"
                        style="--fa-secondary-opacity: 0"
                        swap-opacity
                        size="2x"
                        class="text-slate-300"
                    />
                </span>
            </div>
        </td>

        <td class="table-cell border-none text-sm p-2 text-center align-middle">
            <div
                @click="updateCertificationServiceFn('has_cert')"
                class="cursor-pointer"
                v-if="certification.first_opened_certification_phase"
                v-title
                title="Tiene certificado"
            >
                <span
                    v-if="
                        certification.first_opened_certification_phase
                            .has_cert == 1
                    "
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-square-check"
                        style="--fa-secondary-opacity: 0"
                        swap-opacity
                        size="2x"
                        class="text-emerald-500"
                    />
                </span>
                <span v-else>
                    <font-awesome-icon
                        icon="fa-duotone fa-square"
                        style="--fa-secondary-opacity: 0"
                        swap-opacity
                        size="2x"
                        class="text-slate-300"
                    />
                </span>
            </div>
        </td>

        <td
            class="table-cell border-none text-sm p-2 align-middle text-slate-500"
            @click="updateScheduledAt = true"
        >
            <div
                v-if="certification.first_opened_certification_phase"
                v-title
                title="Fecha de agenda"
            >
                <div class="flex flex-col" v-if="updateScheduledAt">
                    <input
                        class="w-14 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                        type="date"
                        v-model="
                            certification.first_opened_certification_phase
                                .scheduled_at
                        "
                        @keyup.enter="
                            updateCertificationServiceFn('scheduled_at')
                        "
                        @keyup.esc="updateScheduledAt = false"
                        @click="select"
                    />
                </div>
                <span
                    class="cursor-pointer"
                    v-else-if="
                        certification.first_opened_certification_phase
                            .scheduled_at != null
                    "
                    >{{ scheduledAt(certification) }}</span
                >
                <span
                    class="cursor-pointer text-slate-400 italic text-xs"
                    v-else
                    >Agregar fecha</span
                >
            </div>
        </td>

        <td class="table-cell border-none text-sm p-2 items-center">
            <div class="flex flex-row">
                <button
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    v-title
                    title="Documentos"
                    @click="$refs.documentosCertificacion.openModal()"
                    v-if="
                        certification.certification_phases &&
                        certification.certification_phases.length > 0
                    "
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-folders"
                        class="text-lg text-slate-600"
                    />
                </button>

                <button
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    v-title
                    title="Debe crear servicios para ver documentos"
                    v-else
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-folders"
                        class="text-lg text-slate-400 disabled:text-slate-400"
                    />
                </button>

                <button
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    v-title
                    title="Servicios"
                    @click="$refs.serviciosCertificacion.openModal()"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-timeline"
                        class="text-lg text-slate-600"
                    />
                </button>

                <button
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2 relative w-16"
                    v-title
                    title="Comentarios"
                    @click="$refs.comentariosCertificacion.openModal()"
                >
                    <div
                        class="bg-rose-600 text-white rounded-full h-5 w-5 absolute -top-2 -right-2 flex justify-center items-center text-xs"
                        v-if="
                            certification.first_opened_certification_phase &&
                            certification.first_opened_certification_phase
                                .comments
                        "
                    >
                        1
                    </div>
                    <font-awesome-icon
                        icon="fa-duotone fa-comment-lines"
                        class="text-lg text-slate-600"
                    />
                </button>

                <button
                    :class="[
                        auditorsQty
                            ? 'bg-slate-300 cursor-pointer hover:bg-slate-300 '
                            : 'bg-slate-300 cursor-not-allowed',
                    ]"
                    class="shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2 relative w-16"
                    v-title
                    :title="
                        auditorsQty
                            ? 'Auditores disponibles'
                            : 'No hay Auditores disponibles'
                    "
                    @click="$refs.certificationAuditors.openModal()"
                    :disabled="auditorsQty ? false : true"
                >
                    <div
                        class="bg-rose-600 text-white rounded-full h-5 w-5 absolute -top-2 -right-2 flex justify-center items-center text-xs"
                        v-if="auditorsQty"
                    >
                        {{ auditorsQty }}
                    </div>
                    <font-awesome-icon
                        icon="fa-duotone fa-user-tie"
                        class="text-lg text-slate-600"
                        v-if="auditorsQty"
                    />
                    <font-awesome-icon
                        icon="fa-duotone fa-user-xmark"
                        class="text-lg text-slate-400"
                        v-else
                    />
                </button>

                <button
                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-white active:shadow-none transition-shadow ease-out duration-100 mr-2 w-16"
                    @click="$refs.eliminarCertificacion.openModal()"
                    v-title
                    title="Eliminar certificación"
                >
                    <font-awesome-icon
                        icon="fa-duotone fa-trash-can"
                        class="text-lg text-slate-600"
                    />
                </button>
            </div>
        </td>

        <modal ref="documentosCertificacion">
            <template v-slot:header>
                <h2 class="text-xl uppercase">
                    {{ certification.client.account_name }}
                </h2>
                <p class="text-neutral-500 text-lg">
                    {{ certification.norm.norm }} -
                    {{ certification.accreditation.accreditation }}
                </p>
            </template>

            <template v-slot:body>
                <div>
                    <div class="flex flex-row justify-between">
                        <div
                            class="flex flex-col justify-center items-center w-1/4 mt-2"
                        >
                            <span class="font-bold text-slate-500"
                                >No conformidad</span
                            >
                            <select
                                class="bg-slate-100 h-6 placeholder:text-slate-400 placeholder:italic text-xs border-none rounded-xl pl-10 self-center focus:text-slate-700 w-full mb-0 outline-none focus:outline-none my-2"
                                @change="
                                    updateCertificationServiceFn(
                                        'docs_expiration'
                                    )
                                "
                                v-model="docs_expiration_value"
                            >
                                <option value="0">Conforme</option>
                                <option value="30">30 días</option>
                                <option value="60">60 días</option>
                                <option value="90">90 días</option>
                            </select>
                        </div>
                        <div
                            class="flex flex-col justify-center items-center w-1/4"
                        >
                            <span class="font-bold text-slate-500"
                                >Documentos subidos</span
                            >
                            <div
                                @click="updateCertificationServiceFn('docs')"
                                class="cursor-pointer"
                            >
                                <span
                                    v-if="
                                        certification
                                            ?.first_opened_certification_phase
                                            ?.docs == 1
                                    "
                                >
                                    <font-awesome-icon
                                        icon="fa-duotone fa-square-check"
                                        style="--fa-secondary-opacity: 0"
                                        swap-opacity
                                        size="2x"
                                        class="text-emerald-500"
                                    />
                                </span>
                                <span v-else>
                                    <font-awesome-icon
                                        icon="fa-duotone fa-square"
                                        style="--fa-secondary-opacity: 0"
                                        swap-opacity
                                        size="2x"
                                        class="text-slate-300"
                                    />
                                </span>
                            </div>
                        </div>
                        <div
                            class="flex flex-col justify-center items-center w-1/4"
                        >
                            <span class="font-bold text-slate-500"
                                >Veto realizado</span
                            >
                            <div
                                @click="updateCertificationServiceFn('veto')"
                                class="cursor-pointer"
                            >
                                <span
                                    v-if="
                                        certification
                                            ?.first_opened_certification_phase
                                            ?.veto == 1
                                    "
                                >
                                    <font-awesome-icon
                                        icon="fa-duotone fa-square-check"
                                        style="--fa-secondary-opacity: 0"
                                        swap-opacity
                                        size="2x"
                                        class="text-emerald-500"
                                    />
                                </span>
                                <span v-else>
                                    <font-awesome-icon
                                        icon="fa-duotone fa-square"
                                        style="--fa-secondary-opacity: 0"
                                        swap-opacity
                                        size="2x"
                                        class="text-slate-300"
                                    />
                                </span>
                            </div>
                        </div>
                        <div
                            class="flex flex-col justify-center items-center w-1/4"
                        >
                            <span class="font-bold text-slate-500"
                                >Etapa finalizada</span
                            >
                            <div
                                @click="startNewService = true"
                                class="cursor-pointer"
                            >
                                <span
                                    v-if="
                                        certification
                                            .first_opened_certification_phase
                                            .completed == 1
                                    "
                                >
                                    <font-awesome-icon
                                        icon="fa-duotone fa-square-check"
                                        style="--fa-secondary-opacity: 0"
                                        swap-opacity
                                        size="2x"
                                        class="text-emerald-500"
                                    />
                                </span>
                                <span v-else>
                                    <font-awesome-icon
                                        icon="fa-duotone fa-square"
                                        style="--fa-secondary-opacity: 0"
                                        swap-opacity
                                        size="2x"
                                        class="text-slate-300"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div
                        class="bg-slate-200 p-4 rounded-xl mt-4"
                        v-if="startNewService"
                    >
                        <div class="flex flex-row justify-start">
                            <p class="mr-4 w-2/3 text-slate-600">
                                ¿Quiere finalizar la etapa
                                <span class="text-slate-800">{{
                                    certification
                                        .first_opened_certification_phase.phase
                                        .phase_name
                                }}</span>
                                y comenzar con la etapa
                                <span class="text-slate-800">{{
                                    nextPhase
                                }}</span
                                >?
                            </p>
                            <div class="w-1/3 grid grid-cols-2 gap-4">
                                <button
                                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-slate-600 active:shadow-none transition-shadow ease-out duration-100 mr-2"
                                    @click="
                                        updateCertificationServiceFn(
                                            'completed'
                                        );
                                        startNewService = false;
                                    "
                                >
                                    Si
                                </button>
                                <button
                                    class="bg-slate-300 shadow shadow-slate-500/25 cursor-pointer px-5 py-3 uppercase rounded-xl tracking-wide text-sm text-slat-600 active:shadow-none transition-shadow ease-out duration-100 mr-2"
                                    @click="startNewService = false"
                                >
                                    No
                                </button>
                            </div>
                        </div>
                        <span class="italic text-xs text-slate-600 mt-4"
                            >Al seleccionar "Si" se cerrará automáticamente esta
                            ventana y aparecerá la etapa {{ nextPhase }} en esta
                            certificación</span
                        >
                    </div>

                    <!-- <div class="mt-8" @click="updateComments = true">    
                        <div v-if="certification.first_opened_certification_phase">
                            <div class="flex flex-col" v-if="updateComments">
                                <textarea 
                                    v-model="certification.first_opened_certification_phase.comments" 
                                    id="comments" 
                                    class="w-full h-16 text-xs border-neutral-200 rounded-xl"
                                    @keyup.enter="updateCertificationServiceFn('comments')"
                                    @keyup.esc="updateComments = false"
                                    placeholder="Agregue comentarios"
                                >
                                </textarea>
                                
                            </div>
                            <span class="cursor-pointer" v-else-if="certification.first_opened_certification_phase.comments">{{ certification.first_opened_certification_phase.comments }}</span>
                            <span class="cursor-pointer text-neutral-400 italic text-xs" v-else>Agregar comentarios</span> <font-awesome-icon icon="fa-duotone fa-pen" v-if="!updateComments" />
                        </div>
                    
                        
                    </div> -->
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <!-- <p class="text-xs text-emerald-600">Guardado</p> -->
                    <button
                        class="bg-blue-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out mr-2"
                        @click="$refs.documentosCertificacion.closeModal()"
                    >
                        Cerrar
                    </button>
                    <!-- <button class="bg-blue-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out" @click="updateServiceTempBtn(serviceData)">Guardar</button> -->
                </div>
            </template>
        </modal>

        <modal ref="comentariosCertificacion">
            <template v-slot:header>
                <h2 class="text-xl uppercase">
                    {{ certification.client.account_name }}
                </h2>
                <p class="text-neutral-500 text-lg">
                    {{ certification.norm.norm }} -
                    {{ certification.accreditation.accreditation }}
                </p>
            </template>

            <template v-slot:body>
                <div>
                    <div class="mt-8" @click="updateComments = true">
                        <div
                            v-if="
                                certification.first_opened_certification_phase
                            "
                        >
                            <div class="flex flex-col" v-if="updateComments">
                                <textarea
                                    v-model="
                                        certification
                                            .first_opened_certification_phase
                                            .comments
                                    "
                                    id="comments"
                                    class="w-full h-16 text-xs border-neutral-200 rounded-xl"
                                    @keyup.enter="
                                        updateCertificationServiceFn('comments')
                                    "
                                    @keyup.esc="updateComments = false"
                                    placeholder="Agregue comentarios"
                                >
                                </textarea>
                            </div>
                            <span
                                class="cursor-pointer"
                                v-else-if="
                                    certification
                                        .first_opened_certification_phase
                                        .comments
                                "
                                >{{
                                    certification
                                        .first_opened_certification_phase
                                        .comments
                                }}</span
                            >
                            <span
                                class="cursor-pointer text-neutral-400 italic text-xs"
                                v-else
                                >Agregar comentarios</span
                            >
                            <font-awesome-icon
                                icon="fa-duotone fa-pen"
                                v-if="!updateComments"
                            />
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <!-- <p class="text-xs text-emerald-600">Guardado</p> -->
                    <button
                        class="bg-slate-400 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out mr-2"
                        @click="$refs.comentariosCertificacion.closeModal()"
                    >
                        Cerrar sin guardar
                    </button>
                    <button
                        class="bg-blue-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out"
                        @click="
                            updateCertificationServiceFn('comments');
                            $refs.comentariosCertificacion.closeModal();
                        "
                    >
                        Guardar y cerrar
                    </button>
                </div>
            </template>
        </modal>

        <modal ref="serviciosCertificacion">
            <template v-slot:header>
                <h2 class="text-xl uppercase">
                    {{ certification.client.account_name }}
                </h2>
                <p class="text-neutral-500 text-lg">
                    {{ certification.norm.norm }} -
                    {{ certification.accreditation.accreditation }}
                </p>
            </template>

            <template v-slot:body>
                <div class="text-neutral-600">
                    <h3 class="text-lg">Etapas</h3>

                    <div
                        v-if="
                            certification.certification_phases &&
                            certification.certification_phases.length <= 0
                        "
                    >
                        <p>¿Quiere generar el primer ciclo de certificación?</p>
                        <p class="mb-4">
                            El primer ciclo de certificación se genera a partir
                            de la etapa seleccionado y hasta la etapa
                            "recertificación".
                        </p>
                        <div class="grid grid-cols-2 gap-4 items-end">
                            <div>
                                <label for="expires"
                                    >Primera etapa a realizar</label
                                >
                                <select
                                    id="phase"
                                    class="w-14 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                                    v-model="phaseForm.phase_id"
                                >
                                    <option
                                        v-for="phase in phases.slice(0, 4)"
                                        :key="phase.id"
                                        :value="phase.id"
                                    >
                                        {{ phase.phase_name }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label for="expires"
                                    >Fecha de ejecución del servicico</label
                                >
                                <input
                                    id="expires"
                                    class="w-14 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                                    type="date"
                                    v-model="phaseForm.expires"
                                />
                            </div>
                        </div>
                        <div class="w-full flex justify-end mt-8">
                            <button
                                class="bg-blue-600 shadow-lg cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out mr-2"
                                @click="storeCertificationServices()"
                            >
                                Generar
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        <div
                            v-for="(
                                phase, index
                            ) in certification.certification_phases"
                            :key="index"
                        >
                            <div
                                class="bg-neutral-300 rounded-xl p-4 mb-8"
                                v-if="phase.phase_id == 1"
                            >
                                <span class="font-bold text-neutral-500">{{
                                    phases.phase_name
                                }}</span>

                                <div class="grid grid-cols-2">
                                    <div class="flex flex-row items-center">
                                        <span class="mr-2">Fecha fase 2: </span>
                                        <div
                                            @click="
                                                openFieldUpdateServiceForm(
                                                    'expires',
                                                    'expires_' + phase.id,
                                                    phase.expires
                                                )
                                            "
                                        >
                                            <div
                                                class="flex flex-row"
                                                v-if="
                                                    phaseFormField[
                                                        'expires_' + phase.id
                                                    ] == true
                                                "
                                            >
                                                <input
                                                    class="w-14 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                                                    type="date"
                                                    v-model="
                                                        updatePhaseForm.expires
                                                    "
                                                    @keyup.enter="
                                                        updateCertificationPhaseByPhase(
                                                            phase,
                                                            'expires_' +
                                                                phase.id,
                                                            'expires'
                                                        )
                                                    "
                                                    @keyup.esc="
                                                        phaseFormField[
                                                            'expires_' +
                                                                phase.id
                                                        ] = false
                                                    "
                                                />
                                            </div>
                                            <span
                                                class="cursor-pointer"
                                                v-else-if="phase.expires"
                                                >{{
                                                    moment(
                                                        phaseExpiration(phase)
                                                    ).format("DD/MM/YYYY")
                                                }}
                                                <font-awesome-icon
                                                    icon="fa-duotone fa-pen"
                                            /></span>
                                            <span
                                                class="cursor-pointer text-neutral-400 italic text-xs"
                                                v-else
                                                >Agregar Fecha
                                                <font-awesome-icon
                                                    icon="fa-duotone fa-pen"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        class="flex flex-row items-center h-8 text-neutral-600"
                                    >
                                        <span class="mr-2"
                                            >MDs (fase 1 + fase 2):
                                        </span>
                                        <div
                                            @click="
                                                openFieldUpdateServiceForm(
                                                    'man_days',
                                                    'man_days_' + phase.id,
                                                    phase.man_days
                                                )
                                            "
                                        >
                                            <div
                                                class="flex flex-col"
                                                v-if="
                                                    phaseFormField[
                                                        'man_days_' + phase.id
                                                    ] == true
                                                "
                                            >
                                                <input
                                                    class="w-14 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                                                    type="text"
                                                    v-model="
                                                        updatePhaseForm.man_days
                                                    "
                                                    @keyup.enter="
                                                        updateCertificationPhaseByPhase(
                                                            phase,
                                                            'man_days_' +
                                                                phase.id,
                                                            'man_days'
                                                        )
                                                    "
                                                    @keyup.esc="
                                                        phaseFormField[
                                                            'man_days_' +
                                                                phase.id
                                                        ] = false
                                                    "
                                                />
                                            </div>
                                            <span
                                                class="cursor-pointer text-neutral-600"
                                                v-else-if="phase.man_days != 0"
                                                >{{ phase.man_days }}
                                                <font-awesome-icon
                                                    icon="fa-duotone fa-pen"
                                            /></span>
                                            <span
                                                class="cursor-pointer text-neutral-400 italic text-xs"
                                                v-else
                                                >Agregar MD
                                                <font-awesome-icon
                                                    icon="fa-duotone fa-pen"
                                            /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-else class="w-1/3 float-left mb-8">
                                <span class="text-neutral-600 font-semibold">
                                    {{ phase.phase.phase_name }}</span
                                >
                                <div class="flex flex-col justify-start">
                                    <div
                                        @click="
                                            openFieldUpdateServiceForm(
                                                'expires',
                                                'expires_' + phase.id,
                                                phase.expires
                                            )
                                        "
                                    >
                                        <div
                                            class="flex flex-col"
                                            v-if="
                                                phaseFormField[
                                                    'expires_' + phase.id
                                                ] == true
                                            "
                                        >
                                            <input
                                                class="w-14 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                                                type="date"
                                                v-model="
                                                    updatePhaseForm.expires
                                                "
                                                @keyup.enter="
                                                    updateCertificationPhaseByPhase(
                                                        phase,
                                                        'expires_' + phase.id,
                                                        'expires'
                                                    )
                                                "
                                                @keyup.esc="
                                                    phaseFormField[
                                                        'expires_' + phase.id
                                                    ] = false
                                                "
                                            />
                                        </div>
                                        <span
                                            class="cursor-pointer text-neutral-400 leading-8"
                                            v-else-if="phase.expires"
                                        >
                                            Fecha:
                                            <span class="text-neutral-600">{{
                                                moment(
                                                    phaseExpiration(phase)
                                                ).format("DD/MM/YYYY")
                                            }}</span>
                                            <!-- / <span class="text-neutral-600">{{ phaseExpiration(phase) }}</span> 
                                             / <span class="text-neutral-600">{{ phaseExpiration(phase) }}</span> -->
                                            <font-awesome-icon
                                                icon="fa-duotone fa-pen"
                                            />
                                        </span>
                                        <span
                                            class="cursor-pointer text-neutral-400 leading-8 italic text-xs"
                                            v-else
                                            >Agregar Fecha
                                            <font-awesome-icon
                                                icon="fa-duotone fa-pen"
                                        /></span>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        class="flex flex-row items-center h-8 text-neutral-600"
                                    >
                                        <span class="mr-2 text-neutral-400"
                                            >MDs:
                                        </span>
                                        <div
                                            @click="
                                                openFieldUpdateServiceForm(
                                                    'man_days',
                                                    'man_days_' + phase.id,
                                                    phase.man_days
                                                )
                                            "
                                        >
                                            <div
                                                class="flex flex-col"
                                                v-if="
                                                    phaseFormField[
                                                        'man_days_' + phase.id
                                                    ] == true
                                                "
                                            >
                                                <input
                                                    class="w-14 py-0 text-xs mr-2 mb-0 h-8 border border-neutral-200 rounded-xl"
                                                    type="text"
                                                    v-model="
                                                        updatePhaseForm.man_days
                                                    "
                                                    @keyup.enter="
                                                        updateCertificationPhaseByPhase(
                                                            phase,
                                                            'man_days_' +
                                                                phase.id,
                                                            'man_days'
                                                        )
                                                    "
                                                    @keyup.esc="
                                                        phaseFormField[
                                                            'man_days_' +
                                                                phase.id
                                                        ] = false
                                                    "
                                                />
                                            </div>
                                            <span
                                                class="cursor-pointer text-neutral-600 leading-8"
                                                v-else-if="phase.man_days != 0"
                                                >{{ phase.man_days }}
                                                <font-awesome-icon
                                                    icon="fa-duotone fa-pen"
                                            /></span>
                                            <span
                                                class="cursor-pointer text-neutral-400 leading-8 italic text-xs"
                                                v-else
                                                >Agregar MD
                                                <font-awesome-icon
                                                    icon="fa-duotone fa-pen"
                                            /></span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        class="border-none underline text-red-600"
                                        @click="deletePhase(phase.id)"
                                    >
                                        Eliminar etapa
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <button
                        class="bg-slate-300 shadow-lg text-slate-600 shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs active:shadow transition-shadow ease-out mr-2"
                        @click="storeCertificationServices()"
                        v-if="
                            certification.certification_phases &&
                            certification.certification_phases.length > 0
                        "
                    >
                        Generar nuevos servicios
                    </button>
                    <button
                        class="bg-blue-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out mr-2"
                        @click="$refs.serviciosCertificacion.closeModal()"
                    >
                        Guardar y cerrar
                    </button>
                </div>
            </template>
        </modal>

        <modal ref="eliminarCertificacion">
            <template v-slot:header>
                <h2 class="text-xl">Eliminar certificación</h2>
                <p class="text-neutral-500">
                    <span class="uppercase">{{
                        certification.client.account_name
                    }}</span>
                    - {{ certification.norm.norm }} -
                    {{ certification.accreditation.accreditation }}
                </p>
            </template>

            <template v-slot:body>
                <div>¿Está seguro que quiere eliminar esta certificación?</div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <button
                        class="bg-neutral-200 hover:bg-neutral-300 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs active:shadow transition-shadow ease-out mr-2"
                        @click="$refs.eliminarCertificacion.closeModal()"
                    >
                        No, cancelar
                    </button>
                    <button
                        class="bg-rose-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out"
                        @click="deleteCertificationFn()"
                    >
                        Si, eliminar
                    </button>
                </div>
            </template>
        </modal>

        <modal ref="certificationAuditors">
            <template v-slot:header>
                <h2 class="text-xl uppercase">
                    {{ certification.client.account_name }}
                </h2>
                <p class="text-neutral-500 text-lg">
                    {{ certification.norm.norm }} -
                    {{ certification.accreditation.accreditation }}
                </p>
            </template>

            <template v-slot:body>
                <div
                    v-for="eac_norm in certification.eacs_norms"
                    :key="eac_norm.id"
                >
                    <div class="font-bold block">
                        EAC {{ eac_norm.eac.eac }}
                        <span v-if="eac_norm.risk">{{
                            eac_norm.risk.risk
                        }}</span>
                    </div>
                    <div
                        v-if="eac_norm.auditors && eac_norm.auditors.length > 0"
                    >
                        <div
                            v-for="auditor in eac_norm.auditors"
                            :key="auditor.id"
                            class="flex flex-row justify-start items-center gap-4 mb-2"
                        >
                            <span class="w-2/5"
                                >{{ auditor.user?.persona.first_name }}
                                {{ auditor.user?.persona.last_name }}
                            </span>
                            <span
                                class="rounded-xl p-2 text-xs block text-center w-1/5"
                                :class="[
                                    bgBadgeAuditorStatus(auditor.status.id),
                                ]"
                                >{{ auditor.status.status }}</span
                            >
                            <span
                                class="underline w-1/5 cursor-pointer"
                                @click="
                                    $refs.certificationAuditors.closeModal();
                                    $router.push({
                                        path: `/panel/usuarios/${auditor.user.id}/calendario`,
                                    });
                                "
                            >
                                Ver calendario</span
                            >
                        </div>
                    </div>
                    <div v-else>
                        <p>No hay auditores para cubrir este alcance</p>
                    </div>
                </div>
            </template>

            <template v-slot:footer>
                <div class="flex flex-row justify-end">
                    <!-- <p class="text-xs text-emerald-600">Guardado</p> -->
                    <button
                        class="bg-blue-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out mr-2"
                        @click="$refs.certificationAuditors.closeModal()"
                    >
                        Cerrar
                    </button>
                    <!-- <button class="bg-blue-600 shadow-lg shadow-zinc-500/50 cursor-pointer px-8 py-3 uppercase rounded-xl tracking-wide text-xs text-white active:shadow transition-shadow ease-out" @click="updateServiceTempBtn(serviceData)">Guardar</button> -->
                </div>
            </template>
        </modal>

        <certification-eac-modal
            :norms="norms"
            :certification="certification"
            ref="certEacsModal"
        ></certification-eac-modal>
    </tr>
</template>

<style lang="scss" scoped>
select,
input[type="date"] {
    height: 36px;
    width: 100%;
    margin-bottom: 0;
}
</style>
