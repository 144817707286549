<script>
import DashboardCard from "./DashboardCard.vue";
import moment from "moment";

export default {
    name: "DashboardCertificationsWithoutDocs",
    components: {
        DashboardCard,
    },
    props: {
        certificationsWithoutDocs: {
            required: true,
        },
    },
    data() {
        return {
            delay: {
                docs: 2,
            },
        };
    },
    methods: {
        daysSinceEnded(data) {
            return moment().diff(moment(data), "days");
        },
    },
};
</script>

<template>
    <DashboardCard>
        <template #header> Certificaciones sin documentos </template>

        <template #subheader> Parámetro: {{ delay.docs }} días. </template>

        <template #body>
            <ul
                class="h-60 overflow-y-auto"
                v-if="certificationsWithoutDocs.length > 0"
            >
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="certification in certificationsWithoutDocs"
                    :key="certification.id"
                >
                    <span class="col-span-3">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                        >
                            {{ certification.client.account_name }}
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            {{ certification.norm.norm }} -
                            {{ certification.accreditation.accreditation
                            }}<br />
                            <span class="text-rose-500" v-if="certification"
                                >{{
                                    daysSinceEnded(
                                        certification
                                            .first_opened_certification_phase
                                            .scheduled_at
                                    )
                                }}
                                días desde finalización</span
                            >
                        </p>
                    </span>
                </li>
            </ul>
            <div v-else class="italic text-tuvdarkgray">
                No hay información para mostrar
            </div>
        </template>
    </DashboardCard>
</template>
