<script setup>
import { ref } from "vue";
import DashboardCard from "./DashboardCard.vue";
import axios from "axios";
import { useUserStore } from "../stores/user";

const certifications = ref([]);
const useUser = useUserStore();

(async () => {
    await axios
        .get("v2/dashboard/certifications/unfinished", {
            headers: {
                Authorization: "Bearer " + `Bearer ${useUser.token}`,
                "Content-Type": "application/json",
            },
        })
        .then((res) => {
            certifications.value = res.data;
        });
})();
</script>
<template>
    <DashboardCard>
        <template #header> Certificaciones sin finalizar</template>

        <template #subheader
            >Certificaciones cuyo servicio no se dió por finalizado en el
            sistema, tendiendo las condiciones para hacerlo.</template
        >

        <template #body>
            <ul class="h-60 overflow-y-auto" v-if="certifications.length > 0">
                <li
                    class="grid grid-cols-3 gap-x-4 mt-2 hover:bg-slate-200 hover:py-4 rounded-xl px-3 py-2 group transition-all duration-300"
                    v-for="(certification, index) in certifications"
                    :key="index"
                >
                    <span class="col-span-3" v-if="certification.client">
                        <h4
                            class="text-sm text-slate-600 group-hover:text-blue-700 justify-self-start"
                            v-if="certification.client"
                        >
                            {{ certification.client.account_name }}
                        </h4>
                        <p
                            class="font-normal group-hover:text-slate-500 text-slate-400"
                        >
                            <span
                                class="font-normal"
                                v-if="
                                    certification.norm &&
                                    certification.accreditation
                                "
                            >
                                <span v-if="certification.norm">{{
                                    certification.norm.norm
                                }}</span>
                                <span v-if="certification.accreditation">
                                    -
                                    {{
                                        certification.accreditation
                                            .accreditation
                                    }}</span
                                >
                            </span>
                        </p>
                    </span>
                </li>
            </ul>
            <div v-else class="italic text-tuvdarkgray">
                No hay información para mostrar
            </div>
        </template>
    </DashboardCard>
</template>
